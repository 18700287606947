<template>
  <v-container grid-list-xl>
    <v-layout
      fluid
      pt-0
      wrap>
      <v-flex xs12>
        <v-tabs
          v-model="currentTab"
          grow>
          <v-tab href="#driveme">DriveMe</v-tab>
          <v-tab href="#edrivers">E-Drivers</v-tab>
          <v-tab href="#rentabob">Rent A Bob</v-tab>
          <v-tabs-items
            v-model="currentTab"
          >
            <v-tab-item value="driveme">
              <div class="mt-5">driveme</div>
            </v-tab-item>
            <v-tab-item value="edrivers">
              <div class="mt-5">edrivers</div>
            </v-tab-item>
            <v-tab-item
              value="rentabob"
            >
              <v-layout
                row
                wrap>
                <v-flex
                  md6
                  xs12>
                  <app-card
                    heading="Reservation Time Availability"
                    col-classes="xl12 lg12 md12 sm12 xs12"
                    custom-classes="mb-0"
                  >
                    <div class="d-flex">
                      <div>
                        <v-date-picker
                          v-model="reservationTimeDate"
                          :events="reservationTimeEvents"
                          event-color="red lighten-1"
                        />
                      </div>
                      <div class="ml-3">
                        <v-select
                          :items="hoursItems"
                          :disabled="!reservationTimeDate"
                          v-model="reservationTimeHour"
                          label="Hour"
                          item-text="text"
                          item-value="value"
                        />
                        <v-checkbox
                          v-if="reservationTimeHour"
                          v-model="reservationTimeHour.available"
                          label="Available"
                          class="mt-3"
                          @change="(e) => { onChangeReservationTimeAvailability(e) }"
                        />
                      </div>
                    </div>
                  </app-card>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import BrandsFilter from 'Components/BrandsFilter'
import DriverSchedule from '../../../helpers/driver-schedule'
import moment from 'moment-timezone'

export default {
  components: { CrudTable, BrandsFilter },
  mixins: [Request, Brands, DriverSchedule],
  data () {
    return {
      currentTab: 'rentabob',
      reservationTimeDate: null,
      reservationTimeEvents: null,
      reservationTimeHour: null,
      loading: false,
      currentReservationTimes: []
    }
  },
  computed: {
    hoursItems () {
      if (this.currentReservationTimes) {
        const items = this.currentReservationTimes.map(item => {
          let text = `${item.time} - ${item.day}`
          if (!item.available) {
            text += ' - disabled'
          }
          return { text, value: item }
        })
        return items
      }

      return []
    }
  },
  watch: {
    currentTab: {
      handler (val) {
        this.fetchReservationTimes()
      },
      deep: true
    },
    reservationTimeDate: {
      async handler (newDate) {
        this.reservationTimeHour = null
        const result = await this.getReservationTimesByDate(newDate)
        if (result && result.windows) {
          const res = Object.values(result.windows).reduce((acc, currentValue) => {
            return acc.concat(currentValue)
          }, [])
          this.currentReservationTimes = res
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.fetchReservationTimes()
    this.reservationTimeDate = moment().format('YYYY-MM-DD')
  },
  methods: {
    async fetchReservationTimes () {
      if (this.currentTab === 'rentabob') {
        const { data } = await this.request('GET', `/reservation-times/${this.currentTab}/overwrites`)
        this.reservationTimeEvents = data
      }
    },
    async getReservationTimesByDate (date) {
      const { data } = await this.request('GET', `/reservation-times/${this.currentTab}/${date}`)
      return data
    },
    async onChangeReservationTimeAvailability (available) {
      const datetime = this.reservationTimeHour.date + 'T' + this.reservationTimeHour.time
      await this.request('POST', `/reservation-times/${this.currentTab}/${datetime}`, { data: { available } })
      await this.fetchReservationTimes()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
